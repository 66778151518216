<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope">

    </layout>
</template>

<script>
import Layout from '../../components/Layout.vue';

export default {
    name: 'Dashboard',

    components: {
        Layout
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            routeName: 'dashboard',
            classes: {
                dashboard: true
            }
        }
    }
};
</script>